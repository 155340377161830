import { createTheme } from '@mui/material/styles';
import shadows from '@mui/material/styles/shadows';

export const lightTheme = createTheme({
  palette: {
    primary: {
      main: '#eeeeee',
      light: '#f9f9f9',
      dark: '#c9c9c9',
      contrastText: '#333333',
    },
    secondary: {
      main: '#c1d8f0',
      light: '#e1f1fd',
      dark: '#c8d9ed',
      contrastText: '#333333',
    },
    background: {
      default: '#ffffff',
    },
    text: {
      primary: {
        main: '#333333',
        light: '#666666',
        dark: '#090909',
      },
      secondary: {
        main: '#6596ca',
        light: '#99abbf',
        dark: '#202f40',
      },
    },
  },
  shadows: shadows.map(() => 'none'),
  shape: {
    borderRadius: 0,
  },
});

export const darkTheme = createTheme({
  palette: {
    primary: {
      main: '#333333',
      light: '#111111',
      dark: '#666666',
      contrastText: '#f9f9f9',
    },
    secondary: {
      main: '#147808',
      light: '#038542',
      dark: '#038f1f',
      contrastText: '#f9f9f9',
    },
    background: {
      default: '#090909',
    },
    text: {
      primary: {
        main: '#eeeeee',
        light: '#c9c9c9',
        dark: '#f9f9f9',
      },
      secondary: {
        main: '#20c20e',
        light: '#04cf66',
        dark: '#04d92f',
      },
    },
  },
  shadows: shadows.map(() => 'none'),
  shape: {
    borderRadius: 0,
  },
});
