import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import {
  AppBar,
  Box,
  Container,
  Toolbar,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Brand, IntroScreen, ModeToggle, Navigation } from './LayoutNodes';

/**
 * Site layout for all pages
 * 
 * @param children: React object to nest inside layout
 */
function Layout ({ children }) {
  const location = useLocation();
  const theme = useTheme();
  const boxStyles = {
    minHeight: '100vh',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    paddingTop: '48px',
    boxSizing: 'border-box',
    backgroundColor: 'background.default',
    boxShadow: '10px 0 #000000'
  };
  const xPadding = {
    paddingLeft: 'calc(16px + env(safe-area-inset-left))',
    paddingRight: 'calc(16px + env(safe-area-inset-right))',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 'calc(24px + env(safe-area-inset-left))',
      paddingRight: 'calc(24px + env(safe-area-inset-right))',
    },
  };
  const footerStyles = {
    alignSelf: 'flex-end',
    paddingBottom: 'env(safe-area-inset-bottom)',
  };
  const toolbarStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 'env(safe-area-inset-top)',
    ...xPadding,
  };
  const footerToolbarStyles = {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '16px',
    ...xPadding,
    '& span': {
      fontSize: '11pt',
      color: `${theme.palette.text.primary.light}`,
    }
  };
  const navToggleStyles = {
    display: 'flex',
    alignItems: 'center',
    columnGap: '16px',
    whiteSpace: 'nowrap',
  };
  const innerBoxStyles = { 
    display: 'flex',
    flex: 1,
    paddingLeft: 'env(safe-area-inset-left)',
    paddingRight: 'env(safe-area-inset-right)',
  };

  return (
    <>
      <Box sx={ boxStyles }>
        <AppBar position="fixed" component="header" color="primary">
          <Toolbar variant="dense" sx={ toolbarStyles }>
            <Brand />
            <Box sx={ navToggleStyles }>
              <ModeToggle />
              <Navigation location={location} />
            </Box>
          </Toolbar>
        </AppBar>
        <Box sx={ innerBoxStyles }>
          <Container maxWidth="xl" sx={{pt: 2, pb: 2, color: 'text.primary'}}>
            {children}
          </Container>
        </Box>
        <AppBar
          component="footer"
          position="relative"
          color="primary"
          sx={ footerStyles }
        >
          <Toolbar variant="dense" sx={ footerToolbarStyles }>
            <Typography component="span" className="copyright">
              &copy; George (Goce) Pacarski
            </Typography>
            <Typography component="span" className="abn">
              ABN: 45 962 641 978
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <IntroScreen location={location} />
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.any,
};

export default Layout;
