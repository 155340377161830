import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { StyledPicture, TextWrap } from './Nodes';

/**
 * Bold style text
 */
const BoldText = (props) => {
  return (
    <Typography {...props} sx={{fontWeight: 600}} />
  );
};

BoldText.propTypes = { };


/**
 * Stacked and wrapped text container
 */
export const ResumeTextWrap = (props) => {
  const styles = {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
    marginTop: '8px',
    marginBottom: '8px',
  };
  return (
    <TextWrap {...props} sx={styles}  />
  );
};

ResumeTextWrap.propTypes = { };


/**
 * Styled unordered list of skills
 * 
 * @param data: array of strings
 */
export const SkillsList = ({data}) => {
  const Container = styled.ul`
    margin: 0;
    padding-left: 24px;
  `;
  return (
    <Container>
    {data.map((skill, index) => (
      <Box component="li" key={`skillitem-${index}`}>
        {skill}
      </Box>
    ))}
    </Container>
  );
};

SkillsList.propTypes = {
  data: PropTypes.array,
};


/**
 * Educational institution logo
 * 
 * @param data: image data containing filename and title/alt text
 */
const EduLogo = ({data}) => {
  const imgSrc = 
    require(`../assets/images/logos/${data.logo}.png`);
  const webpSrc = 
    require(`../assets/images/logos/${data.logo}.webp`);

  return (
    <StyledPicture imgSrc={imgSrc} webpSrc={webpSrc} type='image/png'
      alt={data.title} width='auto' height='40px' />
  );
};

EduLogo.propTypes = {
  data: PropTypes.object,
};


/**
 * Styled unordered list of courses or modules relevant to a qualification
 * 
 * @param data: array of strings (courses)
 */
const CourseList = ({data}) => {
  const ListContainer = styled.ul``;
  const ListItem = styled.li``;
  return (
    <>
      {data === null && <></>}
      {data !== null &&
      <ListContainer>
        {data.map((course, index) => (
        <ListItem key={`course-${index}`}>
          {course}
        </ListItem>))}
      </ListContainer>
      }
    </>
  );
};

CourseList.propTypes = {
  data: PropTypes.array,
  open: PropTypes.bool,
};


/**
 * Togglable (show/hide) course list
 * 
 * @param data: array of strings (courses)
 */
const Courses = ({data}) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const ToggleContainer = styled.div`
    display: flex;
    flex-direction: column;
  `;
  const ToggleOption = styled.div`
    display: flex;
    align-self: flex-start;
    flex-direction: row;
    align-items: center;
    column-gap: 4px;
    cursor: pointer;
    &:hover {
      color: ${theme.palette.text.primary.light};
    },
    & svg {
      width: 18px;
      height: auto;
      color: ${open ? '#f44336' : theme.palette.text.secondary.dark};
    },
    & div {
      font-size: 10pt;
    }
  `;
  return (
    <>
      {data.courses === null && <></>}
      {data.courses !== null &&
      <ToggleContainer>
        <ToggleOption onClick={() => setOpen(!open)}>
          {open && <>
          <RemoveIcon />
          <Typography component="div">Hide {data.inst.courseTaxon}</Typography>
          </>}
          {!open && <>
          <AddIcon />
          <Typography component="div">Show {data.inst.courseTaxon}</Typography>
          </>}
        </ToggleOption>
        {open && <CourseList data={data.courses} /> }
      </ToggleContainer>
      }
    </>
  );
};

Courses.propTypes = {
  data: PropTypes.object,
};


/**
 * Styled stacked list of qualifications/education
 * 
 * @param data: array of education data
 */
export const EducationList = ({data}) => {
  const ListContainer = styled.ul`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    list-style: none;
    padding: 0;
    margin: 0;
  `;
  const ListItem = styled.li`
    display: flex;
    flex-direction: column;
  `;
  const EduContainer = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 16px;
  `;
  const LogoContainer = styled.div`
    padding: 4px 0;
  `;
  const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
  `;
  const InnerBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    column-gap: 16px;
  `;
  return (
    <ListContainer>
    {data.map((ed, index) => (
      <ListItem key={`resed-item-${index}`}>
        <EduContainer>
          <LogoContainer>
            <EduLogo data={ed.inst} />
          </LogoContainer>
          <TextContainer>
            <BoldText>
              {ed.qual}
            </BoldText>
            <InnerBox>
              <Typography component="span">
                {ed.year}
              </Typography>
              <Typography component="span">
                {ed.inst.title}
              </Typography>
            </InnerBox>
          </TextContainer>
        </EduContainer>
        <Courses data={ed} />
      </ListItem>
    ))}
    </ListContainer>
  );
};

EducationList.propTypes = {
  data: PropTypes.array,
};


/**
 * Inline bolded and normal text
 * 
 * @param title: bold text
 * @param text: normal text
 */
const EmploymentLine = ({title, text}) => {
  return (
    <Box>
      <BoldText component="span">
        {title}:&emsp;
      </BoldText>
      <Typography component="span">
        {text}
      </Typography>
    </Box>
  );
};

EducationList.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};


/**
 * Stacked list of employment history
 * 
 * @param data: array of employment data
 */
export const EmploymentList = ({data}) => {
  const Container = styled.ul`
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    list-style: none;
    padding: 0;
    margin: 0;
  `;
  const InnerBox = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  `;
  return (
    <Container>
    {data.map((item, index) => (
      <Box component="li" key={`emp-item-${index}`}>
        <InnerBox>
          <EmploymentLine title="Year" text={item.year} />
          <EmploymentLine title="Company" text={item.company} />
          <EmploymentLine title="Position" text={item.position} />
          <EmploymentLine title="Duties" text={item.duties} />
        </InnerBox>
      </Box>
    ))}
    </Container>
  );
};

EmploymentList.propTypes = {
  data: PropTypes.array,
};
