import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Link,
  Paper,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LanguageIcon from '@mui/icons-material/Language';
import AppsIcon from '@mui/icons-material/Apps';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ScienceIcon from '@mui/icons-material/Science';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import { StyledPicture } from './Nodes';

export const portfolioNavItems = [
  { "title": "Websites", "path": "websites", "icon": <LanguageIcon /> },
  { "title": "Software", "path": "software", "icon": <AppsIcon /> },
  { "title": "Engineering", "path": "engineering", "icon": <EngineeringIcon /> },
  { "title": "Science", "path": "science", "icon": <ScienceIcon /> },
  { "title": "Hobby", "path": "hobby", "icon": <DevicesOtherIcon /> },
];


/**
 * Portfolio category grid navigation item
 * 
 * @param data: object containing title, icon and hyperlink
 */
export const PortfolioCategoryItem = ({data}) => {
  const theme = useTheme();
  const itemStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '&': {
      textDecoration: 'none',
      color: 'text.primary.main',
      [theme.breakpoints.up('sm')]: {
        minHeight: '200px',
      },
    },
    '&:hover': {
      color: 'text.secondary.main',
    },
    '& svg': {
      fontSize: '48px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '60px',
      },
    },
    '& span': {
      fontSize: '18pt',
      [theme.breakpoints.up('sm')]: {
        fontSize: '24pt',
      },
    },
  };
  return (
    <Paper to={`/portfolio/${data.path}`} component={RouterLink}
      sx={itemStyles}>
      {data.icon}
      <Typography component='span'>
        {data.title}
      </Typography>
    </Paper>
  );
};

PortfolioCategoryItem.propTypes = {
  data: PropTypes.object,
};


/**
 * Styled portfolio image
 * 
 * @param category: image sub-directory
 * @param data: contains filename, title (alt text), and extension
 */
const PortfolioImage = ({category, data}) => {
  const imgSrc = 
    require(`../assets/images/${category}/${data.image}.${data.ext}`);
  const webpSrc = data.hasWebp ?
    require(`../assets/images/${category}/${data.image}.webp`) : null;
  const imgType = `image/${data.ext === 'jpg' ? 'jpeg' : data.ext}`;

  return (
    <StyledPicture imgSrc={imgSrc} webpSrc={webpSrc} type={imgType}
      alt={data.title} width='100%' height='auto' />
  );
};

PortfolioImage.propTypes = {
  category: PropTypes.string,
  data: PropTypes.object,
};


/**
 * One styled portfolio item containing image and text
 * 
 * @param category: image sub-directory
 * @param data: contains filename, title (alt text), extension, item hyperlink
 */
export const PortfolioSingleItem = ({category, data}) => {
  const theme = useTheme();
  const paperStyles = {
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: `${theme.palette.secondary.light}`,
    '&:hover .tinted-text': {
      top: 0,
    },
    '&:hover .item-header': {
      marginTop: 0,
      display: 'block',
      lineClamp: 'none',
      WebkitLineClamp: 'none',
    },
  };
  const ImageContainer = styled.a`
    & {
      display: block;
      position: relative;
      font-size: 0;
      overflow: hidden;
    }
  `;
  const textTransition = 'all 500ms ease-out';
  const TextContainer = styled.a`
    position: absolute;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 8px;
    text-decoration: none;
    background-color: ${theme.palette.secondary.main}dd;
    color: ${theme.palette.text.primary.dark};
    top: calc(100% - 18px);
    box-shadow: 0 -6px 4px 3px ${theme.palette.secondary.main}dd;
    &, & .item-header {
      -webkit-transition: ${textTransition};
      -moz-transition: ${textTransition};
      -o-transition: ${textTransition};
      transition: ${textTransition};
    }
    & .item-header {
      margin-top: -20px;
      display: -webkit-box;
      line-clamp: 1;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    & .item-text {
      flex-grow: 1;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
    & .item-text::-webkit-scrollbar {
      display: none;
    }
  `;
  return (
    <Paper sx={ paperStyles }>
      <ImageContainer href={data.link} target="_blank">
        <PortfolioImage category={category} data={data} />
      </ImageContainer>
      <TextContainer href={data.link} target="_blank" className="tinted-text">
        <Typography component="h3" variant="h6" className="item-header">
          {data.title}
        </Typography>
        <Typography component="span" className="item-text">
          {data.text}
        </Typography>
      </TextContainer>
    </Paper>
  );
};

PortfolioSingleItem.propTypes = {
  category: PropTypes.string,
  data: PropTypes.object,
};


/**
 * Portfolio category sub-navigation
 * 
 * @param currCategory: the current/active category
 */
export const PortfolioNavigation = ({currCategory}) => {
  const theme = useTheme();
  const boxStyles = {
    display: 'flex',
    marginTop: '8px',
    marginBottom: '16px',
    flexDirection: 'row',
    columnGap: '8px',
    [theme.breakpoints.up('sm')]: {
      columnGap: '16px',
    },
  };
  const linkStyles = {
    '&': {
      textDecoration: 'none',
      borderBottom: '1px solid transparent',
      fontSize: '10pt',
      textAlign: 'center',
      color: 'text.primary.main',
      [theme.breakpoints.up('sm')]: {
        fontSize: '12pt',
        textAlign: 'left',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '14pt',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '16pt',
      },
    },
    '&:hover': {
      borderBottomColor: 'text.secondary.light',
      color: 'text.secondary.light',
    },
    '&.current': {
      borderBottomColor: 'text.secondary.main',
      color: 'text.secondary.main',
    },
    '& svg': {
      fontSize: '14pt',
      verticalAlign: 'bottom',
      marginRight: '2px',
    }
  };
  return (
    <Box sx={ boxStyles }>
    {portfolioNavItems.map((item, index) => (
      <Link key={`pfnav-${index}`} to={`/portfolio/${item.path}`}
        component={RouterLink} sx={linkStyles}
        className={item.path === currCategory ? 'current' : ''}>
        {item.icon}
        {item.title}
      </Link>
    ))}
    </Box>
  );
};

PortfolioNavigation.propTypes = {
  currCategory: PropTypes.string,
};
