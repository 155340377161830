import React from 'react';
import PropTypes from 'prop-types';

const GlobalContext = React.createContext(null);

/**
 * Container for React Application to share and persist global data
 * 
 * @param children: React element
 */
export const GlobalProvider = ({ children }) => {
  const isDarkMode = () => {
    const mode = window.localStorage.getItem('mode');
    if (mode === null || mode === '') {
      const isDark = window.matchMedia && 
        window.matchMedia('(prefers-color-scheme: dark)').matches;
      window.localStorage.setItem('mode', isDark ? 'dark' : 'light');
      return isDark;
    }
    return window.localStorage.getItem('mode') === 'dark';
  };

  const [darkMode, setDarkMode] = React.useState(isDarkMode());

  React.useEffect(() => {
    window.localStorage.setItem('mode', darkMode ? 'dark' : 'light');
  }, [darkMode]);

  const globals = {
    darkMode: darkMode,
    setDarkMode: setDarkMode,
  };

  return (
    <GlobalContext.Provider value={globals}>
      {children}
    </GlobalContext.Provider>
  );
};

GlobalProvider.propTypes = {
  children: PropTypes.any,
};

export default GlobalContext;
