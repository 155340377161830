import React from 'react';

/**
 * Home/Landing Page
 */
function HomePage () {
  React.useEffect(() => {
    document.title = process.env.REACT_APP_SITE_NAME;
  }, []);
  
  return (
    <></>
  );
}

HomePage.propTypes = { };

export default HomePage;
