import React from 'react';
import { Typography } from '@mui/material';
import { PageTop, SubTitle } from '../components/Nodes';
import {
  EducationList,
  EmploymentList,
  ResumeTextWrap,
  SkillsList
} from '../components/ResumeNodes';
import { formatTitle } from '../helpers';


/**
 * Returns and array of employment history objects (year, company, position, duties)
 */
const work = () => {
  return [
    {
      year: '2022 – current',
      company: 'N/A (self employed)',
      position: 'Freelance programmer',
      duties: 'Maintaining, adding features to and refreshing websites for BoxTM clients. Clients include adamflipp.com, co-ap.com, collierarchitects.com, crownhair.com.au, kmck.com.au, imagesthatsell.com.au, performpilates.com.au and screen.nsw.gov.au. Web development, programming and server maintenance for Future Food Systems(futurefoodsystems.com.au).'
    },
    {
      year: '2017 – 2022',
      company: 'Box Communications Pty. Ltd. [BoxTM]',
      position: 'Lead Web Developer / Programmer',
      duties: 'Building dynamic websites using PHP frameworks including CakePHP and Yii, and content management systems including WordPress and Drupal. Experience in working with numerous hosting providers, primarily Amazon Web Services and Crucial. Experience also includes database design and build, as well as working with various frontend technologies such as HTML5, CSS3, AJAX, JavaScript and JavaScript libraries including jQuery, jQuery Mobile and others. Other experience includes developing and customising Shopify websites and HTML newsletters.'
    },
    {
      year: '2016 – 2017',
      company: 'WGSNET Pty. Ltd.',
      position: 'Web Developer / Programmer',
      duties: 'Development of responsive brochure and e-commerce websites for clients, using WordPress, WooCommerce and OpenCart including Search Engine Optimisation (SEO). Other experience includes working with Plesk and cPanel.'
    },
    {
      year: '2007 - 2011',
      company: 'Oppenheimer Pty. Ltd.',
      position: 'Food Technologist',
      duties: 'Daily analysis of raw material and finished goods, and supervision of production to ensure adherence to quality system and HACCP, and approval of product in inventory management system as well as maintaining a separate database of production and quality criteria, and issuing Certificates of Analysis to customers. R&D Duties included formulation of new products with various sensory and functional profiles, entering R&D information into a database and setting up successful products for production into the inventory management system. Other duties included administering the computer systems of the quality and R&D departments, including installation, configuration, maintenance, and troubleshooting.'
    },
  ];
};


/**
 * Returns array of objects containing education data (qualification name,
 * institute, logo, course lists)
 */
const education = () => {
  const instUNSW = {
    title: 'University of New South Wales',
    logo: 'unsw-sydney',
    courseTaxon: 'Courses',
  };
  const instWSU = {
    title: 'Western Sydney University (formerly University of Western Sydney)',
    logo: 'wsu',
    courseTaxon: 'Courses',
  };
  const instTAFE = {
    title: 'TAFE NSW',
    logo: 'tafe-nsw',
    courseTaxon: 'Modules',
  };
  const instSJBHS = {
    title: 'Sir Joseph Banks High School',
    logo: 'sjbhs',
    courseTaxon: 'Info',
  };
  
  return [
    {
      qual: 'Bachelor of Science (Computer Science) (with Distinction)',
      inst: instUNSW,  year: '2023', courses: [
        'COMP1511 - Programming Fundamentals',
        'COMP1521 - Computer Systems Fundamentals',
        'COMP1531 - Software Engineering Fundamentals',
        'COMP2041 - Software Construction',
        'COMP2511 - Object Oriented Programming',
        'COMP2521 - Data Structures and Algorithms',
        'COMP3121 - Algorithms and Programming Techniques',
        'COMP3231 - Operating Systems',
        'COMP3311 - Database Systems',
        'COMP3331 - Computer Networks and Applications',
        'COMP3511 - Human Computer Interaction',
        'COMP3900 - Computer Science Project',
        'COMP4920 - Ethics in Computer Science',
        'COMP6080 - Web Front-End Programming',
        'COMP6441 - Security Engineering',
        'COMP6443 - Web Application Security & Testing',
        'COMP6771 - Advanced C++ Programming',
        'COMP1010 - The Art of Computing',
        'MATH1011 - Fundamentals of Mathematics',
        'MATH1081 - Discrete Mathematics',
        'MATH1131 - Mathematics 1A',
        'MATH1231 - Mathematics 1B',
        'PHYS1160 - Introduction to Astronomy',
        'PSYC1001 - Psychology 1A',
      ]
    },
    { 
      qual: 'Diploma of Website Development', inst: instTAFE, year: '2016',
      courses: [
        'BSBWHS501 - Ensure a safe workplace',
        'ICTDBS412 - Build a database',
        'ICTDBS502 - Design a database',
        'ICTDBS504 - Integrate database with website',
        'ICTICT503 - Validate quality and completeness of system design specifications',
        'ICTICT515 - Verify client business requirements',
        'ICTPMG501 - Manage ICT projects',
        'ICTPRG413 - Use a library or pre-existing components',
        'ICTPRG425 - Use structured query language',
        'ICTPRG506 - Design application architecture',
        'ICTTEN5204A - Produce technical solutions from business specifications',
        'ICTWEB502 - Create dynamic web pages',
        'ICTWEB503 - Create web-based programs',
        'ICTWEB504 - Build a document using eXtensible markup language',
        'ICTWEB505 - Develop complex web page layouts',
        'ICTWEB506 - Develop complex cascading style sheets',
        'ICTWEB508 - Develop website information architecture',
        'ICTWEB516 - Research and apply emerging web technology trends',
        'ICTICT418 - Contribute to copyright, ethics and privacy in an ICT environment',
        'ICTPRG406 - Apply introductory object-oriented language skills',
        'ICTPRG415 - Apply skills in object-oriented design',
        'ICTPRG418 - Apply intermediate programming skills in another language',
        'ICTPRG527 - Apply intermediate object-oriented language skills',
        'ICTWEB501 - Build a dynamic website'
      ]
    },
    {
      qual: 'Certificate IV in Web-Based Technologies', inst: instTAFE,
      year: '2015', courses: [
        'BSBOHS302B - Participate effectively in OHS communication and consultative processes',
        'ICAICT401A - Determine and confirm client business requirements',
        'ICAICT417A - Identify, evaluate and apply current industry-specific technologies to meet industry standards',
        'ICAWEB401A - Design a website to meet technical requirements',
        'ICAWEB402A - Confirm accessibility of websites for people with special needs',
        'ICAWEB403A - Transfer content to a website using commercial packages',
        'ICAWEB409A - Develop cascading style sheets',
        'ICAWEB410A - Apply web authoring tool to convert client data for websites',
        'ICAWEB411A - Produce basic client-side script for dynamic web pages',
        'ICAWEB413A - Optimise search engines',
        'ICAWEB414A - Design simple web page layouts',
        'ICAWEB415A - Produce server-side script for dynamic web pages',
        'ICAWEB416A - Customise content management system',
        'ICAWEB417A - Integrate social web technologies',
        'ICAWEB421A - Ensure website content meets technical protocols and standards',
        'ICAWEB424A - Evaluate and select a web hosting service',
        'ICAWEB425A - Apply structured query language to extract and manipulate data',
        'ICAWEB429A - Create a markup language document to specification',
        'ICAWEB501A - Build a dynamic website',
        'BSBCRT401A - Articulate, present and debate ideas',
        'ICADBS403A - Create basic databases',
        'ICTICT418A - Contribute to copyright, ethics and privacy in an IT environment',
        'ICAICT420A - Develop client user interface',
        'ICAPMG401A - Support small scale IT projects',
        'ICAPRG405A - Automate processes',
        'ICAPRG406A - Apply introductory object-oriented language skills',
        'ICAPRG414A - Apply introductory programming skills in another language'
      ]
    },
    {
      qual: 'Certificate IV in Programming', inst: instTAFE, year: '2015',
      courses: [
        'BSBCRT401A - Articulate, present and debate ideas',
        'BSBWHS302A - Apply knowledge of WHS legislation in the workplace',
        'ICADBS403A - Create basic databases',
        'ICTICT418A - Contribute to copyright, ethics and privacy in an IT environment',
        'ICAICT420A - Develop client user interface',
        'ICAPMG401A - Support small scale IT projects',
        'ICAPRG402A - Apply query language',
        'ICAPRG403A - Develop data-driven applications',
        'ICAPGR404A - Test applications',
        'ICAPRG405A - Automate processes',
        'ICAPRG406A - Apply introductory object-oriented language skills',
        'ICAPRG409A - Develop mobile applications',
        'ICAPRG410A - Build a user interface',
        'ICAPRG414A - Apply introductory programming skills in another language',
        'ICAPRG415A - Apply skills in object-oriented design',
        'ICTPRG418A - Apply intermediate programming skills in another language',
        'ICTPRG419A - Analyse software requirements',
        'ICAPRG527A - Apply intermediate object-oriented language skills',
      ]
    },
    {
      qual:'Advanced Diploma of Engineering Technology (Electronics)',
      inst: instTAFE, year: '2014', courses: [
        'UEENEED120A - Design and implement remote access for Internetworking systems',
        'UEENEED121A - Design and implement multi-layer switching for Internetworking systems',
        'UEENEED122A - Design and implement security for Internetworking systems',
        'UEENEED150A - Develop industrial control programs for microcomputer equipped devices',
        'UEENEEE081A - Apply material science to solving electrotechnology engineering problems',
        'UEENEEE101A - Apply Occupational Health and Safety regulations, codes and practices in the workplace',
        'UEENEEE123A - Solve basic problems electronic and digital equipment and circuits',
        'UEENEEE137A - Document and apply measures to control OHS risks associated with electrotechnology work',
        'UEENEEE160A - Provide engineering solutions for uses of materials and thermodynamic effects',
        'UEENEEF104A - Install and modify performance data communication copper cabling',
        'UEENEEF105A - Install and modify optical fibre performance data communication cabling',
        'UEENEEH113A - Troubleshoot amplifiers in an electronic apparatus',
        'UEENEEH114A - Troubleshoot resonance circuits in an electronic apparatus',
        'UEENEEH115A - Develop software solutions for microcontroller based systems',
        'UEENEEH146A - Solve fundamental electronic communications system problems',
        'UEENEEH148A - Design and develop advanced digital systems',
        'UEENEEH160A - Plan large electronic projects',
        'UEENEEH172A - Troubleshoot communication systems',
        'UEENEEH183A - Analyse the performance of wireless-based electronic communication systems',
        'UEENEEI116A - Assemble, enter and verify operating instructions in microprocessor equipped devices',
        'UEENEEI150A - Develop, enter and verify discrete control programs for programmable controllers',
        'UEENEEI154A - Design and use advanced programming tools PC networks and HMI Interfacing',
        'BSBMGT502B - Manage people performance',
        'UEENEED111A - Develop, implement and test object oriented code',
        'UEENEEE117A - Implement and monitor energy sectory OHS policies and procedures',
        'UEENEEE124A - Compile and produce an energy sector detailed report',
        'UEENEEE127A - Use advanced computational processes to provide solutions to energy sector engineering problems',
        'UEENEEH102A - Repairs basic electronic apparatus by replacement of components',
        'UEENEEI151A - Develop, enter and verify word and analogue control programs for programmable logic controllers',
        'UEENEEI155A - Develop structured programs to control external devices',
        'UEENEEI156A - Develop and test code for microcontroller devices',
        'UEENEEI157A - Configure and maintain industrial control system networks',
        'UEENEEK132A - Develop strategies to address environmental and systainability issues in the energy sector',
        'UEENEEC001A - Maintain documentation',
        'UEENEEC002A - Source and purchase materials/parts for installation or service jobs',
        'UEENEED101A - Use computer applications relevant to a workplace',
        'UEENEED102A - Assemble, set-up and test computing devices',
        'UEENEED104A - Use engineering applications software on personal computers',
        'UEENEED112A - Support computer hardware and software for engineering applications',
        'UEENEED115A - Administer computer networks',
        'UEENEED116A - Develop computer network services',
        'UEENEED117A - Install and configure network systems for internetworking',
        'UEENEED118A - Design and implement network systems for internetworking',
        'UEENEED143A - Install and configure a client computer operating system and software',
        'UEENEED146A - Set up and configure basic local area network (LAN)',
        'UEENEED151A - Provide programming solution for computer systems engineering problems',
        'UEENEEE015A - Develop design briefs for electrotechnology projects',
        'UEENEEE038A - Participate in development and follow a personal competency development plan',
        'UEENEEE081A - Apply material science to solving electrotechnology engineering problems',
        'UEENEEE082A - Apply physics to solving electrotechnology engineering problems',
        'UEENEEE101A - Apply Occupational Health and Safety regulations, codes and practices in the workplace',
        'UEENEEE102A - Fabricate, assemble and dismantle utilities industry components',
        'UEENEEE104A - Solve problems in d.c. circuits',
        'UEENEEE105A - Fix and secure electrotechnology equipment',
        'UEENEEE107A - Use drawings, diagrams, schedules, standards, codes and specifications',
        'UEENEEE117A - Implement and monitor energy sector OHS policies and procedures',
        'UEENEEE125A - Provide engineering solutions for problems in complex multiple path circuits',
        'UEENEEE126A - Provide solutions to basic engineering computational problems',
        'UEENEEE137A - Document and apply measures to control OHS risks associated with electrotechnology work',
        'UEENEEH101A - Repair basic computer equipment faults by replacement of modules and sub-assemblies',
        'UEENEEH102A - Repairs basic electronic apparatus faults by replacement of components',
        'UEENEEH111A - Troubleshoot single phase input d.c. power supplies',
        'UEENEEH112A - Troubleshoot digital sub-systems',
        'UEENEEH113A - Troubleshoot amplifiers in an electronic apparatus',
        'UEENEEH114A - Troubleshoot resonance circuits in an electronic apparatus',
        'UEENEEH139A - Troubleshoot basic amplifier circuits',
        'UEENEEH169A - Solve problems in basic electronic circuits',
        'UEENEEH188A - Design and develop electronics - computer systems projects',
        'UEENEEK132A - Develop strategies to address environmental and sustainability issues in the energy sector',
      ]
    },
    {
      qual: 'Advanced Diploma of Engineering Technology (Computer Systems)',
      inst: instTAFE, year: '2013', courses: [
        'UEENEEC001B - Maintain documentation',
        'UEENEEC002B - Source and purchase material/parts for installation or service jobs',
        'UEENEED101A - Use computer applications relevant to a workplace',
        'UEENEED102A - Assemble, set-up and test computing devices',
        'UEENEED103A - Evaluate and modify object oriented code programs',
        'UEENEED104A - Use engineering applications software on personal computers',
        'UEENEED112A - Support computer hardware and software for engineering applications',
        'UEENEED115A - Administer computer networks',
        'UEENEED116A - Develop computer network services',
        'UEENEED117A - Install and configure network systems for internetworking',
        'UEENEED118A - Design and implement network systems for internetworking',
        'UEENEED124A - Integrate multiple computer operating systems on a client server local area network',
        'UEENEED143A - Install and configure a client computer operating system and software',
        'UEENEED146A - Set up and configure basic local area network (LAN)',
        'UEENEED150A - Develop industrial control programs for microcomputer equipped devices',
        'UEENEED151A - Provide programming solution for computer systems engineering problems',
        'UEENEEE015B - Develop design briefs for electrotechnology projects',
        'UEENEEE038B - Participate in development and follow a personal competency development plan',
        'UEENEEE081A - Apply material science to solving electrotechnology engineering problems',
        'UEENEEE082A - Apply physics to solving electrotechnology engineering problems',
        'UEENEEE101A - Apply Occupational Health and Safety regulations, codes and practices in the workplace',
        'UEENEEE102A - Fabricate, assemble and dismantle utilities industry components',
        'UEENEEE104A - Solve problems in d.c. circuits',
        'UEENEEE105A - Fix and secure electrotechnology equipment',
        'UEENEEE107A - Use drawings, diagrams, schedules, standards, codes and specifications',
        'UEENEEE117A - Implement and monitor energy sector OHS policies and procedures',
        'UEENEEE123A - Solve basic problems electroninc and digital equipment and circuits',
        'UEENEEE124A - Compile and produce an energy sector detailed report',
        'UEENEEE125A - Provide engineering solutions for problems in complex multiple path circuits',
        'UEENEEE126A - Provide solutions to basic engineering computational problems',
        'UEENEEE129A - Solve electrotechnical engineering problems',
        'UEENEEE137A - Document and apply measures to control OHS risks associated with electrotechnology work',
        'UEENEEH101A - Repair basic computer equipment faults by replacement of modules and sub-assemblies',
        'UEENEEH102A - Repairs basic electronic apparatus faults by replacement of components',
        'UEENEEH111A - Troubleshoot single phase input d.c. power supplies',
        'UEENEEH112A - Troubleshoot digital sub-systems',
        'UEENEEH113A - Troubleshoot amplifiers in an electronic apparatus',
        'UEENEEH114A - Troubleshoot resonance circuits in an electronic apparatus',
        'UEENEEH115A - Develop software solutions for microcontroller based systems',
        'UEENEEH145A - Develop engineering solutions to analogue electronic problems',
        'UEENEEH148A - Design and develop advanced digital systems',
        'UEENEEH169A - Solve problems in basic electronic circuits',
        'UEENEEH188A - Design and develop electronics - computer systems projects',
        'UEENEEI116A - Assemble, enter and verify operating instructions in microprocessor equipped devices',
        'UEENEEK132A - Develop strategies to address environmental and sustainability issues in the energy sector'
      ]
    },
    {
      qual: 'Certificate II in Computer Assembly and Repair', inst: instTAFE,
      year: '2011', courses: [
        '9117D - Environmentally Sustainable Work',
        '9119A - Electrotechnology OHS',
        '9119B - Workshop practice',
        '9119C - Applied Electrical Technology 1',
        '9133B - Computer Servicing 1',
        '9113C - Industrial Computer Systems',
        '9133F - Introduction to LANS',
        '9133H - Computer Operating Systems and Software',
        '9136A - Electronic Terminating Techniques',
        'UEENEED001B - Use basic computer applications relevant to a workplace',
        'UEENEED002B - Assemble, set up and test personal computers',
        'UEENEED004B - Use engineering applications software',
        'UEENEED043B - Install and configure a computer operating system and software',
        'UEENEED046B - Set up and configure basic local area network',
        'UEENEEE001B - Apply OHS practices in the workplace',
        'UEENEEE002B - Dismantle, assemble and fabricate electrotechnology components',
        'UEENEEE003B - Solve problems in extra-low voltage single path circuits',
        'UEENEEE032B - Document occupational hazards and risks in computer systems',
        'UEENEEE038B - Participate in development and follow a personal competency development plan',
        'UEENEEH001B - Carry out basic repairs to computer equipment by replacement of modules/sub-assemblies'
      ]
    },
    {
      qual: 'Certificate II in Information Technology', inst: instTAFE, 
      year: '2011', courses: [
        'BSBCMN106A - Follow workplace safety procedures',
        'ICAD2012B - Design organisational documents using computing packages',
        'ICAI2015B - Install software packages',
        'ICAS2014B - Connect hardware peripherals',
        'ICAS2017B - Maintain system integrity',
        'ICAS2243B - Detect and protect from spam and destructive software',
        'ICAU1128B - Operate a personal computer',
        'ICAU2005B - Operate computer hardware',
        'ICAU2006B - Operate computing packages',
        'ICAU2013B - Integrate commercial computing packages',
        'ICAU2231B - Use computer operating system',
        'ICAW2001B - Work effectively in an IT environment',
        'ICAW2002B - Communicate in the workplace',
        'ICPMM263B - Access and use the Internet',
        'ICPMM321B - Capture a digital image'
      ]
    },
    {
      qual: 'Bachelor of Applied Science (Food Technology)', inst: instWSU,
      year: '2006', courses: [
        'FS304A - Food Product Development Practicum 3.1',
        'FS322A - Food Evaluation',
        'FS324A - Food Science & Technology 3.1',
        '61651 - Introduction to Operations Management',
        'FS316A - Advanced Dairy Technology',
        'FS326A - Food Science & Technology Practicum 3.2',
        '200300 - Managing People at Work',
        '300297 - Analytical Chemistry 2',
        'NT306A - Nutritional Biochemistry',
        'Fundamentals of Mathematics',
        'Biology 1',
        'Chemistry 1',
        'Chemistry 2',
        'General Microbiology',
        'Analytical Chemistry 2.2',
        'Properties of Food 1.2',
        'Food Science & Technology Practicum 1.1',
        'Biochemistry 1',
        'Food Science & Technology 2.2',
        'Food Science & Technology 2.1',
        'Packaging Science & Technology',
        'Food Microbiology 2.2',
        'Process Engineering 2.1',
        'Process Engineering 2.2',
        'SC204A - Approved Industrial Experience',
      ]
    },
    {
      qual: 'Diploma of Applied Science (Biological & Chemical Technologies)',
      inst: instWSU, year:'2002', courses: [
        'BI005A - Biology 1.1D',
        'CH101A - Introductory Chemistry 1.1D',
        'SC001A - Practicum 1.1D',
        'SC005A - Physics and Mathematics 1.1D',
        'CH102A - Biological Chemistry 1.2D',
        'FS007A - Practicum 1.2D',
        'MI006A - Microbiology 1.2D',
        'ST003A - Statistics 1.2D',
        'BI002A - Biology 2.1D',
        'MI003A - Microbiology 2.1D',
        'PE001A - Process Engineering',
        'SC002A - Practicum (Product Quality Control)',
        'CH002A - Analytical Chemistry',
        'MI007A - Microbiology 2.2D',
        'SC004A - Practicum (Laboratory Quality Assurance)',
        'ST108A - Mathematics 1.2',
        'FS001A - Approved Industrial Experience',
        'FS002A - First Aid',
      ]
    },
    {
      qual: 'Higher School Certificate & School Certificate',
      inst: instSJBHS, year:'1999', courses: [
        'Subjects: Mathematics, English, Chemistry, Physics, Electronics, Engineering Studies, Computing Studies',
        'Achievements: multiple academic medallions, dux of year, first in mathematics, history and computing studies'
      ]
    }
  ];
};

/**
 * Returns array of strings detailing skills
 */
const skills = () => {
  return [
    'Frontend web development: HTML, CSS, ReactJS, jQuery, Material UI, Bootstrap, AJAX and fetch API.',
    'UI/UX and responsive mobile-friendly design and implementation including extensive experience in building user interfaces to exact professional design specifications.',
    'Backend web development and programming.',
    'Software engineering including Agile software development, Scrum and project management.',
    'Relational databases: MySQL/MariaDB, PostgreSQL, SQLite and MSSQL.',
    'Programming languages: Python, PHP, JavaScript (including NodeJS), C, C++ (including STL), C#, Java (including JavaFX), Perl and MIPS.',
    'Algorithm design and implementation.',
    'Object oriented and procedural programming utilising advanced data structures and programming techniques.',
    'Shell scripting: Bash, POSIX shell and numerous UNIX tools.',
    'Experienced with Git version control, as well as GitHub and GitLab.',
    'Web application frameworks and libraries: CakePHP, Yii, ASP.NET, Flask and PyHTML.',
    'Custom theme development for WordPress and Drupal client and e-commerce websites, including WooCommerce integration.',
    'Extensive customisation and extension of premium WordPress themes as child themes and plugins including working with page builders.',
    'Experience with OpenCart and Shopify including theme customisation.',
    'Experience with creating HTML newsletter templates (Campaign Monitor).',
    'Experience with Google Analytics, Google Search Console, SEO and SSL/TLS configuration (including paid and Let’s Encrypt certificates).',
    'Skilled in cybersecurity: secure code development, penetration testing (ethical hacking using a variety of tools and scripting techniques) and cryptography (using strong encryption and hashing algorithms to ensure data integrity and confidentiality).',
    'Operating systems and kernel development (OS161 and GNU/Linux).',
    'Computer network architecture and programming, including comprehensive knowledge of the five-layer, OSI and TCP/IP network models.',
    'GNU/Linux web and application server installation, configuration, hardening (permissions and file ownership), optimisation and DOS/DDOS protection. Experienced with installations running combinations of Nginx, Apache, MySQL/MariaDB, PostgreSQL, SQLite, PHP and Python3.',
    'Experience with AWS EC2 and Lightsail.',
    'Experience with VPS and shared hosting. Includes advanced skill in cPanel and Plesk with numerous Australian and offshore hosting providers, and client email configuration for desktop and mobile mail applications.',
    'Experience with file server configuration and file transfer including SMB, FTP, SFTP and SCP.',
    'Experience with Internet domains and DNS configuration.',
    'Some experience with Android mobile application development.',
    'Experience with Figma, Canva, Jira, Confluence and Google Docs.',
    'Some experience with Salesforce.',
    'Experience with Omron and Rockwell industrial automation hardware. Includes installation and programming of PLC (via ladder logic and structured text), HMI and vision hardware.',
    'Experienced with computer hardware (including custom PC/Workstation builds) and repair as well as LAN/WLAN/WAN hardware installation and configuration.',
    'Experience and knowledge in digital and analogue electronics including soldering.',
    'Proficient in macOS, GNU/Linux (Debian/Ubuntu and CentOS/Fedora/Amazon Linux) and Microsoft Windows including some experience with Windows Server.',
    'Experience with Operating System virtualisation in VMware and VirtualBox.',
    'Microsoft Word, Excel, Access, PowerPoint, Outlook and Project.',
    'Digital media (image, video and audio) creation and manipulation including sound understanding of lossy and lossless compression formats.',
    'Technical background in mathematics, chemistry, microbiology and physics with strong analytical skill and inquisitiveness.',
    'Skilled in HACCP, GMP, GLP, food analysis, sensory evaluation, packaging science and technology and food standards.',
    'Laboratory skills in traditional and automated equipment including HPLC, GC, SCFC, and AAS.',
    'Keen eye for quality, accuracy, creativity and detail.',
    'Time management, organisational, problem solving and multitasking skills.',
    'Exceptional interpersonal skills and fluency in English including outstanding written and verbal communication ability.',
    'Excellent knowledge of Workplace Health and Safety regulations.',
    'Able to work in a team (or alone where required), and to establish a good rapport with colleagues, superiors and clients/customers.',
    'Knowledge of production and people management.',
    'Ability to work in a high pressure environment with daily and weekly deadlines.',
  ];
};


/**
 * Returns array of strings detailing skills
 */
const awards = () => {
  return [
    'Most dedicated and hard-working student of 2013',
    '2004 Quest International Award for food product development',
    'Member of Golden Key International Honour Society',
  ];
};


/**
 * Resume Page
 */
function ResumePage () {
  const title = 'Resume';

  React.useEffect(() => {
    document.title = formatTitle(title);
  }, []);

  return (
    <>
      <PageTop title={title} />
      <ResumeTextWrap>
        <ResumeTextWrap>
          <SubTitle>Overview</SubTitle>
          <Typography component="p">
            I am a talented and highly skilled software engineer with over 12
            years experience in programming and web development. My
            qualifications include degrees in computer science and food
            technology as well as multiple diplomas in engineering, information
            technology and chemical sciences. As an ambitious engineer, I find
            that producing innovative solutions and solving challenging problems
            is a deeply rewarding goal and is reflected in the outstanding
            websites I have developed.
          </Typography>
        </ResumeTextWrap>
        <ResumeTextWrap>
          <SubTitle>Employment History</SubTitle>
          <EmploymentList data={work()} />
        </ResumeTextWrap>
        <ResumeTextWrap>
          <SubTitle>Education &amp; Qualifications</SubTitle>
          <EducationList data={education()} />
        </ResumeTextWrap>
        <ResumeTextWrap>
          <SubTitle>Skills</SubTitle>
          <SkillsList data={skills()} />
        </ResumeTextWrap>
        <ResumeTextWrap>
          <SubTitle>Awards &amp; Memberships</SubTitle>
          <SkillsList data={awards()} />
        </ResumeTextWrap>
        <ResumeTextWrap>
          <SubTitle>Interests</SubTitle>
          <Typography component="p">
            Fitness, nutrition, developments in science and technology,
            computers, cybersecurity, science fiction, reading and learning new
            skills.
          </Typography>
        </ResumeTextWrap>
      </ResumeTextWrap>
    </>
  );
}

ResumePage.propTypes = { };

export default ResumePage;
