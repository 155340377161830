import React from 'react';
import { ErrorNotFound404 } from '../components/Nodes';
import { formatTitle } from '../helpers';

/**
 * Not Found 404 Page
 */
function NotFound404Page () {
  React.useEffect(() => {
    document.title = formatTitle('Page Not Found');
  }, []);

  return (
    <ErrorNotFound404 />
  );
}

NotFound404Page.propTypes = { };

export default NotFound404Page;
