import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box, Breadcrumbs, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

/**
 * Styled breadcrumbs containing icon and textual hyperlinks
 * 
 * @param title: the current page title (without hyperlink)
 * @param breadcrumbs: array of objects containing page links and titles
 */
export const CustomBreadcrumbs = ({title, breadcrumbs}) => {
  const navigate = useNavigate();
  const linkStyle = {
    color: 'text.primary.light',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: 'text.secondary.light',
    },
  };
  const iconStyles = {
    color: 'text.primary.light',
  };
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" sx={ iconStyles } />}
      aria-label="breadcrumb">
      <Typography sx={linkStyle} onClick={() => navigate('/')}>
        <HomeIcon />
      </Typography>
      {breadcrumbs.length > 0 && breadcrumbs.map((bc, index) => (
      <Link key={`bc-${index}`} to={bc.path} sx={linkStyle}
        component={RouterLink}>
        {bc.title}
      </Link>
      ))}
      <Typography color="text.primary.main">
        {title}
      </Typography>
    </Breadcrumbs>
  )
};

CustomBreadcrumbs.propTypes = {
  title: PropTypes.string,
  breadcrumbs: PropTypes.array,
};


/**
 * Styled text paragraph
 */
export const TextParagraph = (props) => {
  const theme = useTheme();
  const styles = {
    '& a': {
      color: `${theme.palette.text.primary.light}`,
      textDecoration: 'underline',
    },
    '& a:hover': {
      color: `${theme.palette.text.primary.dark}`,
    }
  };
  return (
    <Typography {...props} mt={1} mb={1}
      component="p" variant="p" color="text.primary.main" sx={styles} />
  );
};

TextParagraph.propTypes = { };


/**
 * Styled text container
 */
export const TextWrap = (props) => {
  return (
    <Typography {...props} component="div" variant="div"
      color="text.primary.main" />
  );
};

TextWrap.propTypes = { };


/**
 * Top portion of page containing title and breadcrumbs
 * 
 * title: the current page title
 * breadcrumbs: array of objects containing page links and titles
 */
export const PageTop = ({title, breadcrumbs = []}) => {
  return (
    <>
      <CustomBreadcrumbs title={title} breadcrumbs={breadcrumbs} />
      <Typography component="h2" variant="h4" color="text.primary.main">
        {title}
      </Typography>
    </>
  );
};

PageTop.propTypes = {
  title: PropTypes.string,
  breadcrumbs: PropTypes.array,
};


/**
 * Page subtitle
 */
export const SubTitle = (props) => {
  return (
    <Typography {...props} component="h3" variant="h5"
      color="text.primary.light" />
  );
};

SubTitle.propTypes = { };


/**
 * Styled picture element containing source and img fallback
 * 
 * @param imgSrc: image file (jpg, png, gif) location
 * @param webpSrc: webp file location
 * @param type: imgSrc MIME type
 * @param alt: image description
 * @param width: image width
 * @param height: image height
 */
export const StyledPicture = ({imgSrc, webpSrc, type, alt, width, height}) => {
const StyledPictureElement = styled.picture`
    &, & source, & img {
      width: ${width};
      height: ${height};
    }
  `;

  return (
    <StyledPictureElement>
      {webpSrc !== null &&
      <source srcSet={webpSrc} type="image/webp" />}
      <source srcSet={imgSrc} type={type} /> 
      <img src={imgSrc} alt={alt} loading="lazy" />
    </StyledPictureElement>
  );
};

StyledPicture.propTypes = {
  imgSrc: PropTypes.string,
  webpSrc: PropTypes.string,
  type: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};


/**
 * Not Found 404 page element
 */
export const ErrorNotFound404 = () => {
  const styles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    '& svg': {
      fontSize: '100px',
    },
  };
  return (
    <Box sx={styles}>
      <NotInterestedIcon color="error" />
      <Typography component="h2" variant="h3" color="text.primary.main">
        404
      </Typography>
      <Typography component="h3" variant="h5" color="text.primary.main">
        Page Not Found
      </Typography>
    </Box>
  );
};

ErrorNotFound404.propTypes = { };
