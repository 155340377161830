import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import {
  Box,
  ClickAwayListener,
  Grow,
  IconButton,
  Link,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Switch,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import GlobalContext from '../utils/GlobalContext';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { isCurrentPath, isHome, introAnimations } from '../helpers';

const menuItems = [
  { "title": "About", "path": "/about" },
  { "title": "Resume", "path": "/resume" },
  { "title": "Portfolio", "path": `${process.env.REACT_APP_PORTFOLIO_LINK}` },
];

/**
 * Styled inline links
 */
const LinksContainer = (props) => {
  const theme = useTheme();
  const LinksCont = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    row-gap: 16px;
    flex-wrap: ${props.allowWrap && theme.breakpoints.down('xs') ? 'wrap' : 'nowrap'};
    & a {
      text-decoration: none;
      font-size: 16pt;
      color: ${theme.palette.text.primary.main};
      border-bottom: 1px solid transparent;
    }
    & > a:hover, & a.current {
      color: ${theme.palette.text.primary.dark};
      border-bottom-color: ${theme.palette.text.primary.dark};
    }
  `;
  return (
    <LinksCont {...props} />
  )
};

LinksContainer.propTypes = { };


/**
 * Styled contact icon links
 */
const ContactLinks = () => {
  const theme = useTheme();
  const ContactCont = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    & a {
      padding: 0;
      color: ${theme.palette.text.primary.light};
    }
    & a:hover {
      color: ${theme.palette.text.secondary.main};
    }
  `;
  return (
    <ContactCont>
      <Tooltip title={`Email: ${process.env.REACT_APP_EMAIL}`}>
        <IconButton
          aria-label="email"
          component={Link}
          href={`mailto:${process.env.REACT_APP_EMAIL}`}
          disableRipple={true}
        >
          <EmailIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="LinkedIn">
        <IconButton
          aria-label="linkedin"
          component={Link}
          href={process.env.REACT_APP_LINKEDIN}
          target="_blank"
          disableRipple={true}
        >
          <LinkedInIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="GitHub">
        <IconButton
          aria-label="github"
          component={Link}
          href={process.env.REACT_APP_GITHUB}
          target="_blank"
          disableRipple={true}
        >
          <GitHubIcon />
        </IconButton>
      </Tooltip>
    </ContactCont>
  );
};

ContactLinks.propTypes = { };


/**
 * A togglable mobile navigation menu
 * 
 * @param currPath: required for active page styling
 */
const MobileMenu = ({currPath}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) return;
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);
  const menuStyles = {
    height: 'calc(100vh - 48px)',
    width: '100vw',
    backgroundColor: 'primary.light',
  };
  const itemStyles = {
    '&': {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      textDecoration: 'none',
      color: 'text.primary.main',
    },
    '& a:hover': {
      color: 'text.primary.dark',
    },
  };
  const linkStyles = {
    ...itemStyles,
    '& span': {
      borderBottom: '1px solid transparent',
    },
    '&:hover, &.current': {
      color: 'text.primary.dark',
    },
    '&:hover span, &.current span': {
      borderBottomColor: 'text.primary.main',
    },
  };
  const contactLinkStyles = {
    ...itemStyles,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  };
  const iconButtonStyles = {
    height: '48px',
    color: 'text.primary.dark',
    marginRight: '-11px',
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        id="mobile-menu-toggle"
        aria-controls={open ? 'mobile-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        disableRipple={true}
        sx={ iconButtonStyles }
      >
        <MenuIcon />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'right top' : 'right bottom',
            }}
          >
            <Paper sx={ menuStyles }>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="mobile-menu"
                  aria-labelledby="mobile-menu-toggle"
                  onKeyDown={handleListKeyDown}
                >
                {menuItems.map((item, index) => (
                  <MenuItem key={`mobnav-${index}`} onClick={handleClose}
                    sx={itemStyles}>
                    <Link component={RouterLink} to={item.path} sx={linkStyles}
                      className={item.path === currPath ? 'current' : ''}>
                      <Typography component="span">
                        {item.title}
                      </Typography>
                    </Link>
                  </MenuItem>
                ))}
                  <MenuItem onClick={handleClose} sx={contactLinkStyles}>
                    <ContactLinks />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

MobileMenu.propTypes = {
  currPath: PropTypes.string,
};


/**
 * Navigation for desktop and mobile
 * 
 * @param location: location object
 */
export const Navigation = ({location}) => {
  const currItem = menuItems.filter((mi) => isCurrentPath(location, mi.path));
  const currPath = currItem.length > 0 ? currItem[0].path : '';
  return (
    <>
      {useMediaQuery((theme) => theme.breakpoints.up('md')) &&
      <Box component="nav">
        <LinksContainer>
        {menuItems.map((item, index) => (
          <Link key={`nav-${index}`} to={item.path} component={RouterLink}
            className={item.path === currPath ? 'current' : ''}>
            {item.title}
          </Link>
        ))}
          <ContactLinks />
        </LinksContainer>
      </Box>}
      {!useMediaQuery((theme) => theme.breakpoints.up('md')) &&
      <MobileMenu currPath={currPath} />}
    </>
  );
};

Navigation.propTypes = {
  location: PropTypes.object,
};


/**
 * Site branding, the website name
 */
export const Brand = () => {
  const navigate = useNavigate();
  const headerStyles = {
    '&': {
      color: 'text.primary.light',
      textDecoration: 'none',
      fontWeight: 600,
      fontSize: '12pt',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      '@media (min-width: 350px)': {
        fontSize: '16pt',
      },
    },
  };
  return (
    <Typography component="h1" sx={headerStyles} onClick={() => navigate('/')}>
      George Pacarski
    </Typography>
  );
};

Brand.propTypes = { };


/**
 * Toggle light and dark mode status
 */
export const ModeToggle = () => {
  const globals = React.useContext(GlobalContext);
  const boxStyles = {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '2px',
    alignItems: 'center',
  };
  const switchStyles = {
    padding: 0,
    height: '18px',
    width: '38px',
    borderRadius: 0,
    '& .MuiButtonBase-root': {
      padding: 0,
    },
    '& .MuiSvgIcon-root': {
      height: '18px',
      width: 'auto',
      color: 'text.secondary.main',
      opacity: 1,
    },
    '& .MuiSwitch-track, & .Mui-checked.Mui-checked+.MuiSwitch-track': {
      backgroundColor: 'secondary.main',
      opacity: 0.35,
      borderRadius: '3px',
    },
  };
  const typoStyles = {
    fontSize: '7pt',
    textTransform: 'uppercase',
    color: 'text.primary.dark',
    textAlign: 'center',
    fontWeight: 600,
    lineHeight: 0.9,
    maxWidth: '32px',
    whiteSpace: 'normal',
  };

  const setColourMode = (event) => {
    globals.setDarkMode(event.target.checked);
  };

  return (
    <Box sx={boxStyles}>
      <Typography component="div" sx={typoStyles}>
        {globals.darkMode ? 'Dark Mode' : 'Light Mode'}
      </Typography>
      <Switch sx={switchStyles} disableRipple={true} checked={globals.darkMode}
        onChange={(event) => setColourMode(event)} icon={<LightModeIcon />}
        checkedIcon={<DarkModeIcon />} />
    </Box>
  );
};

ModeToggle.propTypes = { };


/**
 * Upper portion of intro screen
 */
const IntroHeader = () => {
  const theme = useTheme();
  const headerStyles = {
    fontSize: '28pt',
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {
      fontSize: '36pt',
    },
  };
  const subtitleStyles = {
    fontSize: '16pt',
    fontWeight: 400,
    [theme.breakpoints.up('sm')]: {
      fontSize: '18pt',
    },
  };
  return (
    <>
      <Typography color="text.primary.light" sx={ headerStyles }>
        George Pacarski
      </Typography>
      <Typography color="text.primary.light" sx={ subtitleStyles }>
        Software Engineer
      </Typography>
    </>
  );
};

IntroHeader.propTypes = { };


/**
 * Lower portion of intro screen containing links
 */
const IntroLinks = () => {
  const theme = useTheme();
  const borderAnimation = keyframes`
    0% {
      background-position: 0% calc(100% + 1px);
    }
    100% {
      background-position: 400% calc(100% + 1px);
    }
  `;

  const linkStyles = {
    background: `linear-gradient(to right, transparent 25%,
      ${theme.palette.text.primary.dark}aa,
      ${theme.palette.text.primary.dark}0a)`,
    backgroundSize: '400% 1px',
    backgroundPosition: '0% calc(100% + 1px)',
    backgroundRepeat: 'no-repeat',
    overflowX: 'hidden',
    animation: `${borderAnimation} 10s ease-in-out infinite reverse`,
  };

  return (
    <LinksContainer allowWrap={true}>
    {menuItems.map((item, index) => (
      <Link key={`intro-${index}`} to={item.path} component={RouterLink}
        sx={linkStyles}>
        {item.title}
      </Link>
    ))}
      <ContactLinks />
    </LinksContainer>
  );
};

IntroLinks.propTypes = { };


/**
 * Fixed position element vertically covering half of screen
 */
const ScreenHalf = (props) => {
  const theme = useTheme();
  const ScrHalf = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    height: 50%;
    left: 0;
    z-index: 9999;
    background-color: ${theme.palette.primary.main};
    box-sizing: border-box;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: calc(16px + env(safe-area-inset-left));
    padding-right: calc(16px + env(safe-area-inset-right));
    ${theme.breakpoints.up('sm')} {
        padding-left: calc(24px + env(safe-area-inset-left));
        padding-right: calc(24px + env(safe-area-inset-right));
    }
  `;
  return (
    <ScrHalf {...props} />
  );
};

ScreenHalf.propTypes = { };


/**
 * Animated (slide open, slide close, no slide) intro screen
 * 
 * @param location: location object
 */
export const IntroScreen = ({location}) => {
  const darkMode = React.useContext(GlobalContext).darkMode;
  const [anim, setAnim] = React.useState({
    ...(isHome()
    ? introAnimations().showNoAnimation
    : introAnimations().hideNoAnimation
  )});

  React.useLayoutEffect(() => {
    const prevPath = window.localStorage.getItem('currPath');
    const currPath = location.pathname;
    window.localStorage.setItem('prevPath', prevPath);
    window.localStorage.setItem('currPath', currPath);
    
    if (prevPath === null || prevPath === 'null') {
      setAnim({
        ...(currPath === '/'
        ? introAnimations().showNoAnimation
        : introAnimations().hideNoAnimation
      )});
    } else if (prevPath === '/') {
      setAnim({
        ...(currPath === '/'
        ? introAnimations().showNoAnimation
        : introAnimations().hideAnimate
      )});
    } else {
      setAnim({
        ...(currPath === '/'
        ? introAnimations().showAnimate
        : introAnimations().hideNoAnimation
      )});
    }
  }, [location]);

  React.useLayoutEffect(() => {
    setAnim({
      ...(isHome()
      ? introAnimations().showNoAnimation
      : introAnimations().hideNoAnimation
    )});
  }, [darkMode]);

  const openAnimTop = keyframes`
    0% {
      top: ${anim.minPos};
    }
    100% {
      top: ${anim.maxPos};
    }
  `;

  const animTop = css`
    animation: ${openAnimTop} 1s forwards;
  `;

  const openAnimBottom = keyframes`
    0% {
      bottom: ${anim.minPos};
    }
    100% {
      bottom: ${anim.maxPos};
    }
  `;

  const animBottom = css`
    animation: ${openAnimBottom} 1s forwards;
  `;
  
  const TopHalf = styled(ScreenHalf)`
    top: ${anim.minPos};
    justify-content: flex-end;
    ${anim.animate && animTop}
  `;

  const BottomHalf = styled(ScreenHalf)`
    bottom: ${anim.minPos};
    justify-content: flex-start;
    ${anim.animate && animBottom}
  `;
  return (
    <>
      <TopHalf>
        <IntroHeader />
      </TopHalf>
      <BottomHalf>
        <IntroLinks />
      </BottomHalf>
    </>
  );
};

IntroScreen.propTypes = {
  location: PropTypes.object,
};
