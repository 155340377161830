/**
 * Returns the formatted page title as a string
 * 
 * @param pageName: name of page
 */
export const formatTitle = (pageName) => {
  return `${pageName} ${process.env.REACT_APP_TITLE_DELIMITER} ${process.env.REACT_APP_SITE_NAME}`;
};

/**
 * Returns true if location contains given path otherwise false
 * 
 * @param location: location object
 * @param path: path string to test
 */
export const isCurrentPath = (location, path) => {
  const loc = location.pathname.replace(/\/+$/, '');
  if (loc === path) return true;
  const regex = /^\/portfolio(\/(websites|software|engineering|science|hobby))*$/;
  return path === `${process.env.REACT_APP_PORTFOLIO_LINK}` && loc.match(regex);
};

/**
 * Returns true if current page is the homepage otherwise false
 */
export const isHome = () => {
  const loc = window.location.pathname.replace(/\/*\s*$/, '');
  return process.env.PUBLIC_URL === loc;
};

/**
 * Returns intro animation definitions (slide open/close or no animation)
 */
export const introAnimations = () => {
  return {
    showNoAnimation: {
      animate: false,
      minPos: '0',
      maxPos: '0',
    },
    hideNoAnimation: {
      animate: false,
      minPos: '-50%',
      maxPos: '-50%',
    },
    showAnimate: {
      animate: true,
      minPos: '-50%',
      maxPos: '0',
    },
    hideAnimate: {
      animate: true,
      minPos: '0',
      maxPos: '-50%',
    },
  };
};