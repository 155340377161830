import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Link } from '@mui/material';
import { ErrorNotFound404, PageTop, TextParagraph, TextWrap } from '../components/Nodes';
import {
  PortfolioNavigation,
  PortfolioSingleItem
} from '../components/PortfolioNodes';
import { formatTitle } from '../helpers';

/**
 * Portfolio data relevant to a given category
 * 
 * @param category: which category (websites, software, engineering, etc) to return data for
 */
const getData = (category) => {
  switch (category) {
    case 'websites':
      return {
        title: 'Websites',
        items: [
          {
            title: 'Future Food Systems CRC',
            text: 'A WordPress website using a custom designed theme I built ' +
                  'for Future Food Systems CRC. I provide technical support ' +
                  'and server maintenance for this and related websites.',
            imgDirectory: category,
            image: 'future-food-systems-2024',
            ext: 'jpg',
            link: 'https://www.futurefoodsystems.com.au',
            hasWebp: true,
          },
          {
            title: 'Adam Flipp',
            text: 'A website built in CakePHP and WordPress that I\'ve made modifications to.',
            imgDirectory: category,
            image: 'adam-flipp',
            ext: 'jpg',
            link: 'https://www.adamflipp.com',
            hasWebp: true,
          },
          {
            title: 'AgInnovators',
            text: 'An advanced website I built for AgInnovators in Drupal. ' +
                  'This website is still in development.',
            imgDirectory: category,
            image: 'aginnovators',
            ext: 'jpg',
            link: 'http://sites.boxtm.com/george/ag/ag-new-website/',
            hasWebp: true,
          },
          {
            title: 'Bathers Beachside',
            text: 'A website I built in the CakePHP framework.',
            imgDirectory: category,
            image: 'bathers-beachside',
            ext: 'jpg',
            link: 'http://archive.boxtm.com/bathers/1/',
            hasWebp: true,
          },
          {
            title: 'BoxTM',
            text: 'A rebuild I did of BoxTM\'s website using the Yii2 PHP framework.',
            imgDirectory: category,
            image: 'boxtm',
            ext: 'jpg',
            link: 'https://www.boxtm.com',
            hasWebp: true,
          },
          {
            title: 'CO-AP',
            text: 'A website I built for CO-AP Architects using the CakePHP framework.',
            imgDirectory: category,
            image: 'co-ap',
            ext: 'jpg',
            link: 'https://www.co-ap.com',
            hasWebp: true,
          },
          {
            title: 'Crown Hair',
            text: 'A website I built for Crown Hair using the CakePHP framework and WordPress.',
            imgDirectory: category,
            image: 'crown-hair',
            ext: 'jpg',
            link: 'https://www.crownhair.com.au',
            hasWebp: true,
          },
          {
            title: 'DLMAU',
            text: 'A website I partially rebuilt using the Yii2 PHP framework.',
            imgDirectory: category,
            image: 'dlmau',
            ext: 'jpg',
            link: 'http://archive.boxtm.com/dlmau/2/',
            hasWebp: true,
          },
          {
            title: 'DLMUS',
            text: 'A website I partially rebuilt using the Yii2 PHP framework.',
            imgDirectory: category,
            image: 'dlmus',
            ext: 'jpg',
            link: 'http://archive.boxtm.com/dlmus/3/',
            hasWebp: true,
          },
          {
            title: 'Future Food Systems CRC (archived)',
            text: 'A WordPress website using a purchased template that I\'ve ' +
                  'done extensive development to. This is the old archived ' + 
                  'website.',
            imgDirectory: category,
            image: 'future-food-systems',
            ext: 'jpg',
            link: 'https://archive.futurefoodsystems.com.au',
            hasWebp: true,
          },
          {
            title: 'Hamilton Funerals',
            text: 'A website I built in the CakePHP framework.',
            imgDirectory: category,
            image: 'hamilton-funerals',
            ext: 'jpg',
            link: 'https://www.hamiltonfunerals.com.au',
            hasWebp: true,
          },
          {
            title: 'Images That Sell',
            text: 'A website I built using the CakePHP framework and WordPress.',
            imgDirectory: category,
            image: 'images-that-sell',
            ext: 'jpg',
            link: 'http://archive.boxtm.com/imagesthatsell/3/',
            hasWebp: true,
          },
          {
            title: 'Jade Yarden Training',
            text: 'A website I built in WordPress.',
            imgDirectory: category,
            image: 'jade-yarden',
            ext: 'jpg',
            link: 'https://web.archive.org/web/20190321011457/http://www.jadeyarden.com/',
            hasWebp: true,
          },
          {
            title: 'John Petrie Sculpture',
            text: 'A website I built using the CakePHP framework.',
            imgDirectory: category,
            image: 'john-petrie-sculpture',
            ext: 'jpg',
            link: 'https://www.johnpetriesculpture.com',
            hasWebp: true,
          },
          {
            title: 'Johnny Greally',
            text: 'A website I built using the CakePHP framework.',
            imgDirectory: category,
            image: 'johnny-greally',
            ext: 'jpg',
            link: 'https://www.johnnygreally.com',
            hasWebp: true,
          },
          {
            title: 'KMcK',
            text: 'A website I built for Kathy McKinnon using the CakePHP framework.',
            imgDirectory: category,
            image: 'kmck',
            ext: 'jpg',
            link: 'https://www.kmck.com.au',
            hasWebp: true,
          },
          {
            title: 'Neil Bradford Design',
            text: 'A website I built using the CakePHP framework.',
            imgDirectory: category,
            image: 'bradford',
            ext: 'jpg',
            link: 'http://archive.boxtm.com/bradford/2/',
            hasWebp: true,
          },
          {
            title: 'Nick Leary',
            text: 'A static webpage I created for Nick Leary.',
            imgDirectory: category,
            image: 'nick-leary',
            ext: 'jpg',
            link: 'http://archive.boxtm.com/nickleary/portal/',
            hasWebp: true,
          },
          {
            title: 'Perform Pilates',
            text: 'A website I built in WordPress.',
            imgDirectory: category,
            image: 'perform-pilates',
            ext: 'jpg',
            link: 'https://www.performpilates.com.au',
            hasWebp: true,
          },
          {
            title: 'Porchlight Films',
            text: 'A website I built in WordPress.',
            imgDirectory: category,
            image: 'porchlight-films',
            ext: 'jpg',
            link: 'https://www.porchlightfilms.com.au',
            hasWebp: true,
          },
          {
            title: 'Screen NSW',
            text: 'A website built in Yii2 that I provide technical support ' +
                  'and updates for.',
            imgDirectory: category,
            image: 'screen-nsw',
            ext: 'jpg',
            link: 'http://archive.boxtm.com/screennsw/3/',
            hasWebp: true,
          },
          {
            title: 'SOS Sleep On Silk',
            text: 'A website built in Shopify.',
            imgDirectory: category,
            image: 'sleep-on-silk',
            ext: 'jpg',
            link: 'https://sos-sleeponsilk.com',
            hasWebp: true,
          },
          {
            title: 'Stephen Collier Architects',
            text: 'A website I built in WordPress',
            imgDirectory: category,
            image: 'stephen-collier-architects',
            ext: 'jpg',
            link: 'https://www.collierarchitects.com',
            hasWebp: true,
          },
          {
            title: 'The Chai Room',
            text: 'A website built in Shopify.',
            imgDirectory: category,
            image: 'the-chai-room',
            ext: 'jpg',
            link: 'https://web.archive.org/web/20181224013230/https://thechairoom.com/',
            hasWebp: true,
          },
          {
            title: 'Totem Road',
            text: 'A website built in Shopify that I\'ve made customisations to.',
            imgDirectory: category,
            image: 'totem-road',
            ext: 'jpg',
            link: 'https://web.archive.org/web/20181118001544/https://www.totemroad.com/',
            hasWebp: true,
          },
          {
            title: 'Kalimat Translation',
            text: 'A website I built in WordPress using a purchased theme.',
            imgDirectory: category,
            image: 'kalimat-translation',
            ext: 'jpg',
            link: 'https://web.archive.org/web/20180806032740/http://kalimat-translation.com/',
            hasWebp: true,
          },
          {
            title: 'Kidz On Porter',
            text: 'A website I built in WordPress using a purchased theme.',
            imgDirectory: category,
            image: 'kidz-on-porter',
            ext: 'jpg',
            link: 'https://web.archive.org/web/20190303094325/http://kidzonporter.com.au/',
            hasWebp: true,
          },
          {
            title: 'Krown Dental',
            text: 'A website I built in WordPress using a extensively customised purchased theme.',
            imgDirectory: category,
            image: 'krown-dental',
            ext: 'jpg',
            link: 'https://web.archive.org/web/20231201171837/https://www.krowndental.com.au/krowndental/',
            hasWebp: true,
          },
          {
            title: 'Safe Energy Engineering',
            text: 'A website I built in WordPress using a extensively customised purchased theme (no longer online).',
            imgDirectory: category,
            image: 'safe-energy-engineering',
            ext: 'jpg',
            link: 'https://web.archive.org/web/20221103203616/http://safeenergyengineering.com.au/',
            hasWebp: true,
          },
          {
            title: 'Secta Engineering',
            text: 'A website I built in WordPress using a purchased theme.',
            imgDirectory: category,
            image: 'secta-engineering',
            ext: 'jpg',
            link: 'http://sectaengineering.com.au/sectaeng/',
            hasWebp: true,
          },
          {
            title: 'Sensei\'s Martial Arts Store',
            text: 'A website I built in WordPress using a extensively customised purchased theme.',
            imgDirectory: category,
            image: 'senseis-martial-arts',
            ext: 'jpg',
            link: 'https://web.archive.org/web/20171006163751/https://senseismartialarts.com/',
            hasWebp: true,
          },
          {
            title: 'SRLSPS',
            text: 'A website I built in WordPress using a customised theme.',
            imgDirectory: category,
            image: 'srlsps',
            ext: 'jpg',
            link: 'https://catalogue.srlsps.com.au',
            hasWebp: true,
          },
          {
            title: 'Straight From The Heart',
            text: 'A website I built using the OpenCart e-commerce PHP platform (no longer online).',
            imgDirectory: category,
            image: 'straight-from-the-heart',
            ext: 'jpg',
            link: 'https://web.archive.org/web/20200313030319/https://sftheart.com.au/shop/',
            hasWebp: true,
          },
          {
            title: 'Tyre Pros',
            text: 'A website I built using the OpenCart e-commerce PHP platform (no longer online).',
            imgDirectory: category,
            image: 'tyre-pros',
            ext: 'jpg',
            link: 'https://web.archive.org/web/20190305022947/http://tyrepros.com.au/',
            hasWebp: true,
          },
          {
            title: 'Orries Entertainment',
            text: 'TAFE project PHP website built as for a client (PhatDee) (no longer online).',
            imgDirectory: category,
            image: 'orries-ent',
            ext: 'jpg',
            link: 'http://orries.com.au',
            hasWebp: true,
          },
          {
            title: 'My Previous Portfolio Website',
            text: 'A previous portfolio website built in PHP.',
            imgDirectory: category,
            image: 'previous-portfolio',
            ext: 'jpg',
            link: 'https://old.gpacarski.com.au/2/',
            hasWebp: true,
          },
          {
            title: 'My First Portfolio Website',
            text: 'A website I built in Dreamweaver.',
            imgDirectory: category,
            image: 'first-portfolio',
            ext: 'jpg',
            link: 'https://old.gpacarski.com.au/1/',
            hasWebp: true,
          },
          {
            title: 'Fan Site',
            text: 'My first website.',
            imgDirectory: category,
            image: 'star-trek',
            ext: 'jpg',
            link: 'https://old.gpacarski.com.au/1/project/information-technology/web-design-and-development/web-authoring/css-assignment01/index.html',
            hasWebp: true,
          },
        ],
      };
    case 'software':
      return {
        title: 'Software',
        items: [
          {
            title: 'Word Ladder',
            text: 'Generates the shortest word ladder(s), a program written in C++ based on a COMP6771 assignment. This is a Linux 64-bit executable.',
            imgDirectory: category,
            image: 'cpp-comp6771-word-ladder',
            ext: 'png',
            link: 'https://download.gpacarski.com.au/cpp-comp6771-word-ladder.zip',
            hasWebp: true,
          },
          {
            title: 'A Minimal Shell',
            text: 'A program that executes user commands, written in C as part of COMP1521. This is a Linux 64-bit executable.',
            imgDirectory: category,
            image: 'c-comp1521-mysh',
            ext: 'png',
            link: 'https://download.gpacarski.com.au/c-comp1521-mysh.zip',
            hasWebp: true,
          },
          {
            title: 'Virtual Memory Simulator',
            text: 'A virtual memory simulator I wrote in C as part of COMP1521. This is a Linux 64-bit executable.',
            imgDirectory: category,
            image: 'c-comp1521-vmsim',
            ext: 'gif',
            link: 'https://download.gpacarski.com.au/c-comp1521-vmsim.zip',
            hasWebp: false,
          },
          {
            title: 'Stat',
            text: 'Display detailed file information in terminal, a program I wrote in C as part of COMP1521. This is a Linux 64-bit executable.',
            imgDirectory: category,
            image: 'c-comp1521-stat',
            ext: 'png',
            link: 'https://download.gpacarski.com.au/c-comp1521-stat.zip',
            hasWebp: true,
          },
          {
            title: 'Pokédex',
            text: 'A command line Pokédex I built in C. This was one of my assignments in COMP1511. This is a Linux 64-bit executable.',
            imgDirectory: category,
            image: 'c-comp1511-pokedex',
            ext: 'png',
            link: 'https://download.gpacarski.com.au/c-pokedex.zip',
            hasWebp: true,
          },
          {
            title: 'Spiral',
            text: 'A command line spiral generator, that I wrote in C as part of COMP1511. This is a Linux 64-bit executable.',
            imgDirectory: category,
            image: 'c-comp1511-spiral',
            ext: 'png',
            link: 'https://download.gpacarski.com.au/c-comp1511-spiral.zip',
            hasWebp: true,
          },
          {
            title: 'Tip Calculator',
            text: 'An Android application I built as part of my Certificate IV in Prorgramming used to calculate gratuity.',
            imgDirectory: category,
            image: 'tip-calc',
            ext: 'gif',
            link: 'https://download.gpacarski.com.au/android-tip-calculator.zip',
            hasWebp: false,
          },
          {
            title: 'Restaurant Order Calculator',
            text: 'A Java application I built as part of my Certificate IV in Prorgramming used to calculate the total for an order.',
            imgDirectory: category,
            image: 'order-calc',
            ext: 'gif',
            link: 'https://download.gpacarski.com.au/java-order-calculator.zip',
            hasWebp: false,
          },
          {
            title: 'ABC College',
            text: 'A command-line student management application I built in Java as part of my Certificate IV in Prorgramming.',
            imgDirectory: category,
            image: 'abc-college',
            ext: 'gif',
            link: 'https://download.gpacarski.com.au/java-abc-college.zip',
            hasWebp: false,
          },
          {
            title: 'Padstow Hospital',
            text: 'A hospital management application built in C# using MS-SQL that I built as part of my Certificate IV in Prorgramming.',
            imgDirectory: category,
            image: 'padstow-hospital',
            ext: 'gif',
            link: 'https://download.gpacarski.com.au/c-sharp-padstow-hospital.zip',
            hasWebp: false,
          },
          {
            title: 'Binary Converter',
            text: 'Convert text to 8-bit binary characters (this was my first C++ application I built during my Adv. Dip. in Computer Systems). This is a Windows 32-bit executable.',
            imgDirectory: category,
            image: 'text-to-binary',
            ext: 'gif',
            link: 'https://download.gpacarski.com.au/cpp-text-to-binary.zip',
            hasWebp: false,
          },
          {
            title: 'Text Encoder-Decoder',
            text: 'Create a text, encrypt and decrypt a text file (this was my first C application I built during my Adv. Dip. in Computer Systems). This is a Windows 32-bit executable.',
            imgDirectory: category,
            image: 'c-encdec',
            ext: 'gif',
            link: 'https://download.gpacarski.com.au/c-encdec.zip',
            hasWebp: false,
          },
          {
            title: 'Multiuse Calculator',
            text: 'Simple mathematical operations and electrical resistance calculator (this was my second C application I built during my Adv. Dip. in Computer Systems). This is a Windows 32-bit executable.',
            imgDirectory: category,
            image: 'multiuse-calc',
            ext: 'gif',
            link: 'https://download.gpacarski.com.au/c-multiuse-calc.zip',
            hasWebp: false,
          },
        ],
      };
    case 'engineering':
      return {
        title: 'Engineering',
        items: [
          {
            title: 'Garage Door Opener (Omron)',
            text: 'A touch screen and sensor operated garage door opener with variable speed motor control. The system utilizes an Omron CP-series PLC, Omron NB-series HMI, Omron photoelectric and inductive sensors, an Omron 3-phase inverter, and was programmed using CX-Programmer and NB-Designer using Ethernet based TCP/IP networking for communication.',
            imgDirectory: category,
            image: 'omron-project',
            ext: 'jpg',
            link: 'https://download.gpacarski.com.au/garage-door-opener-training-tool.pdf',
            hasWebp: true,
          },
          {
            title: 'Garage Door Opener (Rockwell)',
            text: 'A touch screen and sensor operated garage door opener with variable speed motor control. The system exclusively utilizes Allen-Bradley hardware including PLC, HMI, 3-phase inverter, photoelectric and inductive sensors and was programmed using Rockwell\'s Connected Components Workbench. Communications implemented include USB and Ethernet based TCP/IP networking.',
            imgDirectory: category,
            image: 'rockwell-project',
            ext: 'jpg',
            link: 'https://download.gpacarski.com.au/rockwell-plc-hmi-project.pdf',
            hasWebp: true,
          },
          {
            title: 'Omron Vision',
            text: 'A touch screen and sensor operated conveyor belt quality inspection system. The system exclusively implements Omron hardware including a CP-series PLC, NB-series HMI, 3-phase inverter, photoelectric and inductive sensors, and an FQ2 series Smart Camera. Communication between devices and other nodes such as computers uses Ethernet based TCP/IP networking.',
            imgDirectory: category,
            image: 'omron-vision',
            ext: 'jpg',
            link: 'https://download.gpacarski.com.au/omron-product-inspection-system.pdf',
            hasWebp: true,
          },
          {
            title: 'Digital Stopwatch',
            text: 'A digital stopwatch that counts from 00:00 to 99:99 (140 minutes and 0 seconds) that I constructed using JK flip flops, binary counters, logic gates, a 555 timer, 7-segment decoders, 7-segment displays, resistors and a capacitor, which operates on a 5V DC power supply. The report outlines the preliminary testing done, calculations, implementation of a working prototype and finally construction of the final device.',
            imgDirectory: category,
            image: 'stopwatch',
            ext: 'jpg',
            link: 'https://download.gpacarski.com.au/stopwatch-project-report.pdf',
            hasWebp: true,
          },
          {
            title: 'Client-Server Imaging',
            text: 'A project involving creation of multiple instances of a standard operating environment workstation image, including single boot and multi-boot Windows and Linux environments using Symantec Ghost, configured for authentication via Active Directory. Images hosted on Windows Server were deployed to multiple workstations via a TCP/IP network, using PXE boot and TFTP.',
            imgDirectory: category,
            image: 'client-server',
            ext: 'jpg',
            link: 'https://download.gpacarski.com.au/client-server-imaging.pdf',
            hasWebp: true,
          },
        ],
      };
    case 'science':
      return {
        title: 'Science',
        items: [
          {
            title: 'Supercritical Carbon Dioxide Extraction of Carotenes from Carrots',
            text: '',
            imgDirectory: category,
            image: 'supercritcal-carotene',
            ext: 'jpg',
            link: 'https://download.gpacarski.com.au/supercritical-fluid-extraction.pdf',
            hasWebp: true,
          },
          {
            title: 'Aetiology of Type 2 Diabetes Mellitus (NIDDM)',
            text: '',
            imgDirectory: category,
            image: 'aetiology-diabetes',
            ext: 'jpg',
            link: 'https://download.gpacarski.com.au/aetiology-of-type-2-diabetes.pdf',
            hasWebp: true,
          },
        ],
      };
    case 'hobby':
      return {
        title: 'Hobby',
        items: [
          {
            title: 'Colour Blend',
            text: 'Blend 2 colours defined by hexadecimal or RGBA values. I created this single page application in TypeScript.',
            imgDirectory: category,
            image: 'colour-blend',
            ext: 'png',
            link: 'https://hobby.gpacarski.com.au/colour-blend/',
            hasWebp: true,
          },
          {
            title: 'Calculator',
            text: 'A multifunction calculator.',
            imgDirectory: category,
            image: 'calculator-2016',
            ext: 'png',
            link: 'https://hobby.gpacarski.com.au/old-pre2017/calculator.html',
            hasWebp: true,
          },
          {
            title: 'Unit Converter',
            text: 'A unit converter developed to convert metric and imperial units of measurement.',
            imgDirectory: category,
            image: 'unit-converter-2016',
            ext: 'png',
            link: 'https://hobby.gpacarski.com.au/old-pre2017/',
            hasWebp: true,
          },
          {
            title: 'Hex-Dec CSS Tool',
            text: 'A tool to convert CSS RGB colours from hexadecimal to decimal and vice versa. Useful in previewing appearance of colours on different devices.',
            imgDirectory: category,
            image: 'hexdec-css-tool-2017',
            ext: 'png',
            link: 'https://hobby.gpacarski.com.au/old-pre2017/hexdec-css-tool.html',
            hasWebp: true,
          },
          {
            title: 'Binary Decode Game',
            text: 'Test how fast you can decode 8-bit binary as decimal.',
            imgDirectory: category,
            image: 'binary-decode-game-2017',
            ext: 'png',
            link: 'https://hobby.gpacarski.com.au/old-pre2017/binary-decode-game.html',
            hasWebp: true,
          },
        ],
      };
    default:
      return false;
  }
};


/**
 * Portfolio Category Page (/porfolio/:category)
 */
function PortfolioCategoryPage () {
  const { category } = useParams();
  const data = getData(category);
  React.useEffect(() => {
    document.title = formatTitle(data ? data.title : 'Page Not Found');
  }, [data]);
  const breadcrumbs = [{path: '/portfolio', title: 'Portfolio'}];
  return (
    <>
      {!data && <ErrorNotFound404 />}
      {data && <>
      <PageTop title={data.title} breadcrumbs={breadcrumbs} />
      <PortfolioNavigation currCategory={category} />
      <TextWrap>
        <TextParagraph>
          Shown below are selected works I've created or being involved in
          creating for clients, educational purposes or as a hobby. Source code,
          scripts and full versions documents including content not featured
          here is available at <Link href={process.env.REACT_APP_GITHUB}
          target="_blank">{process.env.REACT_APP_GITHUB}</Link>.
          Please <Link href={`mailto:${process.env.REACT_APP_EMAIL}`}
          target="_blank">email me</Link> if you have a legitimate need to
          access my private repositories.
        </TextParagraph>
        <TextParagraph>
          Please do not redistribute copies of my work. I explicitly forbid
          misuse of my intellectual property such as it being made available on
          third party websites or otherwise used for nefarious purposes.
        </TextParagraph>
      </TextWrap>
      <Grid container spacing={2} mt={2}>
      {data.items.map((item, index) => (
        <Grid item key={`pcitem-${index}`} xl={4} lg={4} md={4} sm={6} xs={12}>
          <PortfolioSingleItem category={category} data={item} />
        </Grid>
      ))}
      </Grid>
      </>}
    </>
  );
}

PortfolioCategoryPage.propTypes = { };

export default PortfolioCategoryPage;