import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import { lightTheme, darkTheme } from './theme';
import GlobalContext, { GlobalProvider } from './utils/GlobalContext';
import Layout from './components/Layout';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ResumePage from './pages/ResumePage';
import PortfolioPage from './pages/PortfolioPage';
import PortfolioCategoryPage from './pages/PortfolioCategoryPage';
import NotFound404Page from './pages/NotFound404Page';

/**
 * The React Application with global togglable colour (light/dark) mode
 */
const AppInner = () => {
  const darkMode = React.useContext(GlobalContext).darkMode;
  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Layout>
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="/about" element={<AboutPage />} />
            <Route exact path="/resume" element={<ResumePage />} />
            <Route exact path="/portfolio" element={<PortfolioPage />} />
            <Route exact path="/portfolio/:category"
              element={<PortfolioCategoryPage />} />
            <Route path="*" element={<NotFound404Page />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </ThemeProvider>
  );
};

AppInner.propTypes = { };

/**
 * The React Application entry point
 */
function App() {
  return (
    <GlobalProvider>
      <AppInner />
    </GlobalProvider>
  );
}

App.propTypes = { };

export default App;
