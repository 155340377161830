import React from 'react';
import { PageTop, TextParagraph, TextWrap } from '../components/Nodes';
import { formatTitle } from '../helpers';

/**
 * About Page
 */
function AboutPage () {
  const title = 'About';

  React.useEffect(() => {
    document.title = formatTitle(title);
  }, []);

  return (
    <>
      <PageTop title={title} />
      <TextWrap>
        <TextParagraph>
          My name is George Pacarski (offcially Goce Pacarski). I am a highly
          skilled and ambitious software engineer with many years experience
          in building secure user centric websites with advanced features and
          outstanding performance. I have a Bachelor of Science in Computer
          Science from the University of New South Wales (UNSW), in which I
          graduated with Distinction. I also have diplomas in web development,
          electronics and computer systems, all completed with outstanding
          academic results. Hence I have a deep understanding of how software
          operates on and relates to computer system hardware in order to make
          operation efficient and reliable and how to program industrial
          hardware.
        </TextParagraph>
        <TextParagraph>
          My desire and ability is to engineer, innovate and build products
          whether they be websites, software or hardware to a high standard and
          high level of detail and precision which I find is a deeply rewarding
          pursuit. This includes ensuring efficient and correct operation of the
          product, while also ensuring it’s designed and built in such a way
          that it’s secure and protected against malicious actors that might try
          to exploit a system for nefarious purposes. In other words I’m a
          talented security minded engineer driving by desire to build great
          products and thrive on developing solutions to challenging engineering
          problems.
        </TextParagraph>
        <TextParagraph>
          My former career was working as a food technologist for several years
          after having graduated with my Bachelor in Applied Science in Food
          Technology. Prior to that and after completing my higher school
          certificate I graduated with a Diploma of Applied Science in
          Biological and Chemical Technologies from the University of Western
          Sydney (which has since been rebranded to Western Sydney University).
          I’ve always had a deep interest in understanding how things work and
          applying that knowledge to create better products, hence my broad and
          extensive educational background in STEM fields. I have a total of
          three university qualifications and seven VET (TAFE-NSW)
          qualifications, hence I deeply value education for its intrinsic
          benefits and believe the acquisition of knowledge and skills is its
          own reward. The advancement of science and technology means I believe
          in lifelong learning to remain up to date with the latest technology.
          Hence my technical background, experience and adaptability is only
          matched by my motivation to create great products.
        </TextParagraph>
        <TextParagraph>
          On a personal note I enjoy meeting people and conversing on a broad
          range of topics. Learning about advancements in science and technology
          particularly with regards to computer software and hardware as well as
          cybersecurity has always been an interest of mine. Computing is more
          than a career, it's my passion and my lifestyle. Indeed I use macOS
          and Windows but I also use Debian Linux on a daily basis as both a
          desktop and server operating system (as well as Fedora/RHEL based
          Amazon Linux and is what this website runs on). As an tech ethusiast
          and engineer I like to tinker and experiment with software and
          hardware including different Linux and BSD distros.
        </TextParagraph>
        <TextParagraph>
          Another interest of mine is science fiction because it allows one to
          consider unknown, unexplored or impossible phenomena but also imagine
          things that might be possible in the future. My interest in fitness
          and nutrition also keeps me in relatively good shape (or at least
          working towards that goal).
        </TextParagraph>
      </TextWrap>
    </>
  );
}

AboutPage.propTypes = { };

export default AboutPage;
